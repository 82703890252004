import { useEffect, useState,React } from "react";
import { BsCart2 } from "react-icons/bs";
import { AiFillShop } from "react-icons/ai";
import { SiBrandfolder } from "react-icons/si";
import { BsFillPersonCheckFill } from "react-icons/bs";
import Chart from "react-apexcharts";
import { Variables } from "../Variations/Variations";

import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  var CryptoJS = require("crypto-js");
  const navigate = useNavigate();
  const [orders, setOrders] = useState(0);
  const [stores, setStores] = useState(0);
  const [brands, setBrands] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [lineChartOptions, setLineChartOptions] = useState({
    colors: ["#da0035"],
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
  });
  const [lineChartSeries, setLineChartSeries] = useState([
    {
      name: "Sales",
      data: [],
    },
  ]);
  const [pieOptions, setPieOptions] = useState({
    title: {
      text: "Sales per Group",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#da0035 ",
      },
    },
    colors: ["#da0035", "#da0035", "#da0035", "#da0035", "#da0035"],
    labels: [],
  });
  const [pieSeries, setPieSeries] = useState([]);
  const [pieNoSalesOptions, setPieNoSalesOptions] = useState({
    colors: ["#da0035"],
    labels: ["No Sales"],
  });
  const [pieNoSalesSeries, setPieNoSalesSeries] = useState([100]);

  function fetchData() {
      fetch( Variables.API_URL+ "Dashboard/Stats", {
      method: "GET",
      headers: {
        Accept: "application/json",
        
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setBrands(Result.BrandsCount);
        setCustomers(Result.CustomersCount);
        setOrders(Result.OrdersCount);
      });
  }

  function fetchOrdersPerGroup() {
      fetch( Variables.API_URL+ "Dashboard/GetOrdersPerGroup", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        var groupnames = [];
        var totalorders = [];
        Result.map((x) => {
          groupnames.push(x.GroupName);
          totalorders.push(x.TotalOrders);
        });
        setPieSeries(totalorders);
        setPieOptions({
          title: {
            text: "Sales per Group",
            align: "right",
            margin: 10,
            offsetX: -40,
            offsetY: 290,
            floating: true,
            style: {
              color: "#da0035",
            },
          },
          colors: ["#da0035", "#da0035", "#da0035", "#da0035", "#da0035"],
          labels: groupnames,
        });
      });
  }

  function fetchSalesPerMonth() {
      fetch( Variables.API_URL+ "Dashboard/GetSalesPerMonth", {
      method: "GET",
      headers: {
        Accept: "application/json",
        
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        var month = [];
        var totalsales = [];
        Result.map((x) => {
          month.push(x.MonthName);
          totalsales.push(x.TotalSales);
        });
        setLineChartSeries([
          {
            name: "SalesPerMonth",
            data: totalsales,
          },
        ]);
        setLineChartOptions({
          stroke: {
            width: 3,
          },
          colors: ["#000"],
          chart: {
            id: "basic-bar",
          },

          xaxis: {
            categories: month,
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "black",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
            axisBorder: {
              show: true,
              color: "#000",
              height: 2,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
            title: {
              text: "Month",
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#000",
                fontSize: "inherit",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-xaxis-title",
              },
            },
          },
          yaxis: {
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#000",
              height: 0,
              offsetX: 0,
              offsetY: 0,
            },
            axisBorder: {
              show: true,
              color: "#000",
              height: 2,
              offsetX: 0,
              offsetY: 0,
            },
            title: {
              text: "Sales per Month",
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#000",
                fontSize: "inherit;",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-xaxis-title",
              },
            },
          },
        });
      });
  }

  useEffect(() => {
    
      fetchData();
      fetchOrdersPerGroup();
      fetchSalesPerMonth();
    
  }, []);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="rightSide">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-10">
                      <h5 className="card-title"> Dashboard</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-md-4 col-xl-3"
                      onClick={() => navigate("/Orders")}
                    >
                      <div className="card bg-c-pink order-card">
                        <div className="card-block">
                          <h6 className="m-b-20">Orders</h6>
                          <h2 className="text-right">
                            {" "}
                            <BsCart2 className="dashboardicon" />
                            <span>
                              <span id="ContentPlaceHolder1_lblTotalIncome">
                                {orders}
                              </span>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>

               

                    <div
                      className="col-md-4 col-xl-3"
                      onClick={() => navigate("/Brand")}
                    >
                      <div className="card bg-c-lightpink order-card">
                        <div className="card-block">
                          <h6 className="m-b-20">Brand</h6>
                          <h2 className="text-right">
                            <SiBrandfolder className="dashboardicon" />
                            <span>{brands}</span>
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-4 col-xl-3"
                      onClick={() => navigate("/Customers")}
                    >
                      <div className="card bg-c-pink order-card">
                        <div className="card-block">
                          <h6 className="m-b-20">Customers</h6>
                          <h2 className="text-right">
                            <BsFillPersonCheckFill className="dashboardicon" />
                            <span>{customers}</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7">
                      <Chart
                        options={lineChartOptions}
                        series={lineChartSeries}
                        type="area"
                        width="650"
                      />
                    </div>
                    <div className="col-5">
                      {pieSeries.length > 0 ? (
                        <Chart
                          options={pieOptions}
                          series={pieSeries}
                          type="pie"
                          width="500"
                          className="pieChart"
                        />
                      ) : (
                        <Chart
                          options={pieNoSalesOptions}
                          series={pieNoSalesSeries}
                          type="pie"
                          width="500"
                          className="pieChart"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Dashboard;