import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link, NavLink } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { AiOutlineRight } from "react-icons/ai";
const AddMedia = () => {
  //# region variables
  const [media, setMedia] = useState([]);
  //# end region variables

  //# handle

  const handleDrop = async (files) => {
    var count = 0;
    let imageArray = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      // let reader = new FileReader();
      if (file.size >= 2000000) {
        count = count + 1;
      } else {
        imageArray.push(file);
      }
    }
    if (
      (imageArray.length === files.length && count === 0) ||
      (imageArray.length + count === files.length && imageArray.length > 0)
    ) {
      const formData = new FormData();
      imageArray.forEach((image) => formData.append("Images", image));
      try {
        const res = await axios.post(
          Variables.API_URL + "UploadMedia/UploadImageToServer",
          formData
        );
        if (res.data.length >0 ) {
          res.data.forEach((item)=>{
           fetch(Variables.API_URL + "UploadMedia/InsertMedia", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              image: item,
              imageTitle: "",
              altText: "",
              description: "",
              caption: "",
            }),
          }).then((Response) => {
            if (Response.ok) {
              media.push({ Image: item });
            } else {
              toast.error(Response);
            }
          });
          })
          toast.success("Uploaded SuccessFully")
    
        }
      } catch (ex) {
        console.log(ex);
      }
    }else if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  // useEffect(() => {
  //   GetMedia();
  // }, []);
  //#end handle

  return (
    <div className="rightSide">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-12">
              <h2>Upload Media</h2>
              <ul className="portalPath">
                <li>
                  Home <AiOutlineRight />
                </li>
                <li>
                  <Link to="/Media">
                    Media <AiOutlineRight />
                  </Link>
                </li>
                <li>Upload Media</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mb-3 text-right">
                    <NavLink to="/Media" className="btn btn-secondary">
                      Back
                    </NavLink>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <section className="container"></section>
                      <Dropzone onDrop={handleDrop} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images, or click to select files</p>
                          </div>
                        )}
                      </Dropzone>
                      <br />
                      <div className="container-fluid">
                        <div className="row">
                          {media.map((image, i) => (
                            <div className="col-2 p-10" key={i}>
                              <div className="image-container">
                                <img
                                  alt="media"
                                  src={Variables.API_PUBLISHED + image.Image}
                                  width="100"
                                  height="100"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
{
}

export default AddMedia;
